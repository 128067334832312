import React from 'react';
import renderDocument from '../renderDocument';

const ContactSection = ({ email, phone, fax, address, enquiries }) => (
  <>
    <div className="col-md-4" style={{ marginTop: 20, fontSize: 20 }}>
      <table className="table table-borderless table-no-padding">
        <tbody>
          <tr>
            <td>
              <span className="icon-envelope" />
            </td>
            <td>
              <a href={`mailto:${email}`}>{email}</a>
            </td>
          </tr>
          <tr>
            <td>
              <span className="icon-phone" />
            </td>
            <td>{phone}</td>
          </tr>
          <tr>
            <td>
              <span className="icon-fax" />
            </td>
            <td>{fax}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="col-md-5" style={{ marginTop: 20 }}>
      <table className="table">
        <tbody>
          {enquiries.map(enquiry => {
            const [name, email] = enquiry.split(' - ');
            const [address, domain] = email.split('@');
            return (
              <tr key={name}>
                <th>{name}</th>
                <td>
                  <a href={`mailto:${email}`}>
                    {address}@
                    <span className="visible-xs" style={{ display: 'block' }}>
                      {domain}
                    </span>
                    <span className="hidden-xs">{domain}</span>
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>

    <div className="col-md-3" style={{ whiteSpace: 'pre' }}>
      {renderDocument(address)}
    </div>
  </>
);

export default ContactSection;
